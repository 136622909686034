<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title> 课程证书 </template>
    </HeaderBox>
    <!-- 表格列表 -->
    <div class="table-box">
      <a-table
        class="table-template"
        :rowKey="(item) => item.courseId"
        :columns="columns"
        :data-source="tableData"
        :loading="loading"
        @change="onPage"
        :pagination="{
          total: total,
          showTotal: (res) => `共${total}条`,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '30', '50', '100'],
        }"
      >
        <template slot="index" slot-scope="item, row, i">
          <div style="text-align: center">
            {{ i + 1 }}
          </div>
        </template>
        <!-- 学习人数 -->
        <template slot="study" slot-scope="text, record">
          <div class="btn_router_link">
            <span @click="goLearnPage(record)">{{ text }}</span>
          </div>
        </template>
        <!-- 获证人数 -->
        <template slot="obtain" slot-scope="text, record">
          <div class="btn_router_link">
            <span @click="goObtainPage(record)">
              {{ text }}
            </span>
          </div>
        </template>
        <!-- 证书模板 -->
        <template slot="template" slot-scope="text, record">
          <div class="btn_router_link">
            <div style="position: relative; overflow: hidden">
              <span v-show="text" style="color: #3681ef; cursor: pointer">
                {{ record.certifName }}</span
              >
              <span v-show="!text">--</span>
              <viewer>
                <img
                  style="
                    position: absolute;
                    top: -10px;
                    left: 0;
                    opacity: 0;
                    cursor: pointer;
                  "
                  class="inneer_img"
                  :src="record.certifPicture"
                />
              </viewer>
            </div>
          </div>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
const columns = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    dataIndex: "index",
    scopedSlots: { customRender: "index" },
  },
  {
    title: "课程名称",
    align: "center",
    dataIndex: "courseName",
  },
  {
    title: "证书名称",
    align: "center",
    dataIndex: "certifName",
  },
  {
    title: "证书模板",
    align: "center",
    dataIndex: "certifPicture",
    scopedSlots: { customRender: "template" },
  },
  {
    title: "学习人数",
    align: "center",
    dataIndex: "learnNumber",
    scopedSlots: { customRender: "study" },
  },
  {
    title: "获证人数",
    align: "center",
    dataIndex: "certifNumber",
    scopedSlots: { customRender: "obtain" },
  },
];
import HeaderBox from "@/components/HeaderBox.vue";

export default {
  // 可用组件的哈希表
  components: { HeaderBox },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      columns, // 表头
      tableData: [], // 列表数据
      loading: false, // 列表加载
      pageNumber: 1,
      pageSize: 10,
      total: 1,
    };
  },
  // 事件处理器
  methods: {
    // 分页
    onPage(e) {
      this.pageNumber = e.current;
      this.pageSize = e.pageSize;
      this.getdataList()
    },
    // 学习人数详情页
    goLearnPage(e) {
      this.$router.push("/admin/Certificate/Learner?id=" + e.courseId);
    },
    // 获证人数详情页
    goObtainPage(e) {
      this.$router.push({
        path: "/admin/Certificate/CertifiedPersonnel?id=" + e.courseId,
      });
    },
    // 获取数据
    getdataList() {
      this.loading = true
      this.$ajax({
        url:
          "/hxclass-management/course-certif/manage/coursePersonal?pageNum=" +
          this.pageNumber +
          "&pageSize=" +
          this.pageSize,
        method: "get",
      }).then((res) => {
        this.loading = false
        if (res.code == 200 && res.success) {
          this.tableData = res.data.records;
          this.total = res.data.total;
        }
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.getdataList();
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.btn {
  margin-right: 24px;
}
.table-box {
  /deep/ .ant-tabs-bar {
    border-bottom: none;
  }
}
/deep/.right-box {
  padding-bottom: 5px !important;
}
.modalDiv {
  > p {
    font-weight: 500;
    color: #333333;
  }
  .code-item {
    > span {
      display: inline-block;
      min-width: 100px;
      margin: 5px 0;
      margin-right: 15px;
    }
  }
}
.btn_router_link {
  cursor: pointer;
}
</style>
